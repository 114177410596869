import _faHouse from "./faHouse";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
var source = _faHouse;
exports.definition = {
  prefix: source.prefix,
  iconName: source.iconName,
  icon: [source.width, source.height, source.aliases, source.unicode, source.svgPathData]
};
exports.faHome = exports.definition;
exports.prefix = source.prefix;
exports.iconName = source.iconName;
exports.width = source.width;
exports.height = source.height;
exports.ligatures = source.aliases;
exports.unicode = source.unicode;
exports.svgPathData = source.svgPathData;
exports.aliases = source.aliases;
export default exports;
export const __esModule = exports.__esModule,
  definition = exports.definition,
  faHome = exports.faHome,
  prefix = exports.prefix,
  iconName = exports.iconName,
  width = exports.width,
  height = exports.height,
  ligatures = exports.ligatures,
  unicode = exports.unicode,
  svgPathData = exports.svgPathData,
  aliases = exports.aliases;